<template>
  <NuxtLoadingIndicator :height="4" color="#6366f1" />
  <div v-if="authenticated">
    <header
      class="bg-white absolute inset-x-0 top-0 z-10 flex h-16 border-b border-gray-900/10"
    >
      <div
        class="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8"
      >
        <div class="flex flex-1 items-center gap-x-6">
          <button
            type="button"
            class="-m-3 p-3 md:hidden"
            @click="mobileMenuOpen = true"
          >
            <span class="sr-only">Open main menu</span>
            <BaseIcon
              name="heroicons:bars-3"
              class="h-5 w-5 text-gray-900"
              aria-hidden="true"
            />
          </button>
          <SiteLogoText class="h-6" />
        </div>
        <nav
          class="hidden md:flex md:gap-x-11 md:text-sm md:font-semibold md:leading-6 md:text-gray-700"
        >
          <a class="flex gap-x-3 items-center" href="/prompts/folders/all">
            <BaseIcon name="heroicons:arrow-uturn-left" />
            Back to app
          </a>
        </nav>
        <div class="flex flex-1 items-center justify-end gap-x-8">
          <UserDropdown />
        </div>
      </div>
      <Dialog
        as="div"
        class="lg:hidden"
        @close="mobileMenuOpen = false"
        :open="mobileMenuOpen"
      >
        <div class="fixed inset-0 z-50" />
        <DialogPanel
          class="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10"
        >
          <div class="-ml-0.5 flex h-16 items-center gap-x-6">
            <button
              type="button"
              class="-m-2.5 p-2.5 text-gray-700"
              @click="mobileMenuOpen = false"
            >
              <span class="sr-only">Close menu</span>
              <BaseIcon
                name="heroicons:x-mark"
                class="h-6 w-6"
                aria-hidden="true"
              />
            </button>
            <div class="-ml-0.5">
              <a href="#" class="-m-1.5 block p-1.5">
                <span class="sr-only">PromptDrive</span>
                <SiteLogoText class="h-6 w-auto" />
              </a>
            </div>
          </div>
          <div class="mt-2 space-y-2">
            <a class="flex gap-x-3 items-center" href="/prompts/folders/all">
              <BaseIcon name="heroicons:arrow-uturn-left" />
              Back to app
            </a>
          </div>
        </DialogPanel>
      </Dialog>
    </header>

    <div class="mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 lg:px-8">
      <aside
        class="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20"
      >
        <nav class="flex-none px-4 sm:px-6 lg:px-0">
          <ul
            role="list"
            class="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
          >
            <li v-for="item in secondaryNavigation" :key="item.name">
              <BaseLink
                v-if="item.show"
                :href="item.href"
                :class="[
                  route.fullPath == item.href
                    ? 'bg-white text-indigo-600'
                    : 'text-gray-700 hover:text-indigo-600 hover:bg-white',
                  'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold',
                ]"
              >
                <BaseIcon
                  :name="item.icon"
                  :class="[
                    route.fullPath == item.href
                      ? 'text-indigo-600'
                      : 'text-gray-400 group-hover:text-indigo-600',
                    'h-6 w-6 shrink-0',
                  ]"
                  aria-hidden="true"
                />
                {{ item.name }}
              </BaseLink>
            </li>
          </ul>
        </nav>
      </aside>

      <main class="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
        <div
          class="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none"
        >
          <slot />
        </div>
      </main>
    </div>
  </div>
  <BaseToast />
</template>

<script setup>
import { ref } from 'vue'
import {
  Dialog,
  DialogPanel,
  Switch,
  SwitchGroup,
  SwitchLabel,
} from '@headlessui/vue'
import useAuthStore from '~/stores/useAuthStore'
import { storeToRefs } from 'pinia'

const authStore = useAuthStore()
await authStore.fetch()

const route = useRoute()
const { user, authenticated } = storeToRefs(authStore)
const mobileMenuOpen = ref(false)
const automaticTimezoneEnabled = ref(true)
const canSeeBilling = authStore.can('ownerOrAdmin')
const secondaryNavigation = [
  {
    name: 'Profile',
    href: '/settings/profile',
    icon: 'heroicons:user',
    show: true,
  },
  {
    name: 'Settings',
    href: '/settings',
    icon: 'heroicons:cog',
    show: canSeeBilling,
  },
  {
    name: 'Billing',
    href: '/settings/billing',
    icon: 'heroicons:credit-card',
    show: canSeeBilling,
  },
  {
    name: 'Team',
    href: '/settings/users',
    icon: 'heroicons:user-group',
    show: canSeeBilling,
  },
]
</script>
